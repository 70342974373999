import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Configuration } from "@page2flip/core/common";

@Component({
  selector: 'p2f-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.css']
})
export class WelcomePopupComponent implements OnInit {

  imageUrl: string | null = null;
  config: Configuration;
  popupImagePosition: string;
  popupTextPosition: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { configuration: Configuration },
    private dialogRef: MatDialogRef<WelcomePopupComponent>,
    private http: HttpClient
  ) {
    this.config = this.data.configuration;
    this.popupImagePosition = (this.config.layout?.popupImagePosition?.toLocaleLowerCase()) || 'center';
    this.popupTextPosition = (this.config.layout?.popupTextPosition?.toLocaleLowerCase()) || 'center';
  }

  ngOnInit() {
    this.setResponsiveWidth();
    this.setProperty();
    if (this.config.popUp.popUpAsset) {
      this.fetchImage(this.modifyUrl(this.config.popUp.popUpAsset));
    }
  }

  private setProperty() {
    document.documentElement.style.setProperty('--popupBackgroundColor', this.config.design.colors.popupBackgroundColor || "#404040");
    document.documentElement.style.setProperty('--popupTextColor', this.config.design.colors.popupTextColor || '#ffffff');
  }

  modifyUrl(assetPath: string): string {
    const baseUrl = environment.apiUrl.replace('/api', '');
    return `${baseUrl}${assetPath}`;
  }

  fetchImage(url: string) {
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imageUrl = reader.result as string;
      };
      reader.readAsDataURL(blob);
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private setResponsiveWidth(): void {
    if (window.innerWidth <= 600) {
      this.dialogRef.updateSize('100%', 'auto');
    } else if (window.innerWidth <= 1024) {
      this.dialogRef.updateSize('80%', 'auto');
    } else {
      this.dialogRef.updateSize('55%', 'auto');
    }
  }
}
