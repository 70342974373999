import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@page2flip/core';
import { CoreCommonModule } from '@page2flip/core/common';
import { CoreModule as ViewerCoreModule } from '../core/core.module';
import { MaterialModule } from '../material/material.module';
import { DialogComponent } from './dialog/dialog.component';
import { DocumentComponent } from './document/document.component';
import { HotspotLayerComponent } from './hotspot-layer/hotspot-layer.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { SidePanelService } from './side-panel/side-panel.service';
import { ZoomComponent } from './zoom/zoom.component';
import { LightgalleryModule } from "lightgallery/angular";
import { WelcomePopupComponent } from './welcome-popup/welcome-popup.component';

const declarations: any[] = [
  DialogComponent,
  DocumentComponent,
  HotspotLayerComponent,
  SidePanelComponent,
  ZoomComponent
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    MaterialModule,
    ViewerCoreModule,
    LightgalleryModule
  ],
  exports: [
    CommonModule,
    CoreCommonModule,
    MaterialModule,
    ...declarations
  ],
  declarations: [...declarations, WelcomePopupComponent],
  entryComponents: [DialogComponent],
  providers: [SidePanelService]
})
export class BaseModule {}
