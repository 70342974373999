<mat-dialog-content class="popup-content">
  <div class="popup-layout text" [ngClass]="popupTextPosition">
    <div class="welcome-text" [innerHtml]="config.popUp.welcomePopUpMessage"></div>
  </div>
  <div class="popup-layout image" [ngClass]="popupImagePosition" *ngIf="imageUrl">
    <a *ngIf="config.popUp.popUpAssetLink" [href]="config.popUp.popUpAssetLink" target="_blank">
      <img [src]="imageUrl" alt="Popup Image">
    </a>
    <img *ngIf="!config.popUp.popUpAssetLink" [src]="imageUrl" alt="Popup Image">
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()">Close</button>
</mat-dialog-actions>
